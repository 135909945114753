import React from 'react'
import { MotionDiv } from '../components/MotionCmp'
import { SITES } from '../settings/settings'
import { useStore } from '../store/store-actions'

export const Footer = () => {
	const {store} = useStore()

    return (
        <section id="contact" className="section">
			<div className="section-divider">
				<MotionDiv initial={{ width: '5%' }} animate={{ width: '100%'}} transition={{ duration: 0.6, delay: 0}} className="divider"></MotionDiv>
				<div className="section-divider-title">
					<MotionDiv>
                        <h6 className="section-title">CONTACTO</h6>
                    </MotionDiv>
					<div className="section-bullet"><ion-icon name="radio-button-on-outline"></ion-icon></div>
				</div>
			</div>
			<div className="section-content">
				<div className="row">
					<div className="col-md-5 justify-content-center text-center">
						<MotionDiv transition={{ duration: 0.5, delay: 0.4 }}>
							<h2 className="hi-text">Reservas<br></br> via email<br></br>o WhatsApp</h2>
						</MotionDiv>
					</div>
					<div className="col-md-3">
						<MotionDiv transition={{ duration: 0.5, delay: 0.6 }}>
							<div className="h6">Email</div>
							<div><a href={`mailto:${store.site.email}`}>{store.site.email}</a></div>
							<br></br>
							<div className="h6">Teléfono</div>
							<div><a href={`tel:${store.site.phone}`}>{store.site.phone}</a></div>
						</MotionDiv>
					</div>
					<div className="col-md-4">
					</div>
				</div>
				<div className="contact-abs m-hidden">
					<MotionDiv initial={{ opacity: 0, x: 150 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.5 }} className="abs">
						<h2>CONTACTO</h2>
					</MotionDiv>
				</div>
			</div>
		</section>
    )
}