import { useContext } from "react"
import { Site } from "../settings/settings"
import { actions, Store, StoreContext } from "./store"

export const useStore = () => {
    const {state, dispatch} = useContext(StoreContext)

    return {
        store: state as Store,
        setSite: (data: Site) => dispatch({type: actions.SET_SITE, payload: data})
    }
}