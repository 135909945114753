import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { motion } from "framer-motion"
import { useStore } from '../store/store-actions'
import { Site, SITES } from '../settings/settings';

export default function Header() {

    const {store, setSite} = useStore()

    useEffect(() => {
		const domain = window.location.hostname
        if(domain !== 'localhost'){
            const site = SITES.find(s => s.domain === domain) as Site
            if(site){
                setSite(site)
            }
        }
    }, [])

    return (
        <header className="header">
            <div className="row">
                <div className="col-md-4 d-flex justify-content-md-start justify-content-center">
                    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1}} transition={{ duration: 0.6, delay: 0.4 }} className="logo"><Link to={`/`} dangerouslySetInnerHTML={{ __html: store.site.logo }}></Link></motion.span>
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1}} transition={{ duration: 0.6, delay: 0.6 }} className="logo h6">{new Date().getFullYear()}</motion.span>
                </div>
                <div className="col-md-4 justify-content-md-end justify-content-center text-right text-m-center">
                    <motion.span><a href={`tel:${store.site.phone}`} className="h6">{store.site.phone}</a></motion.span>
                </div>
            </div>
        </header>
    )
}